import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import Select from '../../utils/Form/Widgets/Select'
import FileInput from '../../utils/Form/Widgets/FileInput'
import Check from '../../utils/Form/Widgets/Check'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Company {
  name?: string
  phone?: string
  logo?: number
  sole_trader?: boolean
  fiscal_day?: number
  fiscal_month?: number
  sic_code?: string
  sic_code_status?: boolean
  number_of_empleyees?: number
  status?: string
  is_reseller?: number
  target_consumption_ratio?: number
}

class CompanyEditModal extends FormHandler {
  statusOptions = [
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'passive',
      label: 'Disabled',
    },
  ]

  isResellerOptions = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },
  ]

  constructor(props: Props) {
    super(props)
    this.state = { entity: new Company() }
  }

  getApiUrl(): string {
    return '/company/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/company/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      companies: this.state.entity,
    }
  }

  getFiscalDayOptions() {
    return Array.from(Array(31).keys()).map((i) => ({
      value: (i + 1).toString(),
      label: (i + 1).toString(),
    }))
  }

  getFiscalMonthOptions() {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return Array.from(Array(12).keys()).map((i) => ({
      value: (i + 1).toString(),
      label: monthNames[i],
    }))
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Company'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = entity.name
    }
    let defaultCountryValue = state.entity?.company_addresses?.[0]?.country || 0
    if (this.props.itemIdForUpdate === 'add' || !state.entity?.company_addresses?.[0]) {
      // Default United Kingdom
      defaultCountryValue = 231
    } else {
      defaultCountryValue = state.entity?.company_addresses?.[0]?.country
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Name'
                className='form-control'
                type='text'
                name='name'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.name}
              />
            </FormGroup>
            <FormGroup>
              <FileInput
                fileUploadKeyUrl='/company/getCompanyLogoUploadKey'
                title='Company Logo'
                name='logo'
                onChange={(event) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.logo}
                accept='image/*'
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Fiscal Day'
                name='fiscal_day'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                options={this.getFiscalDayOptions()}
                defaultValue={state.entity.fiscal_day}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Fiscal Month'
                name='fiscal_month'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                options={this.getFiscalMonthOptions()}
                defaultValue={state.entity.fiscal_month}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Number Of Employees'
                className='form-control'
                min={0}
                name='number_of_empleyees'
                pattern='^\d+$'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.number_of_empleyees}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Status'
                name='status'
                options={this.statusOptions}
                defaultValue={state.entity.status}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Check
                title='Sole Trader'
                name='sole_trader'
                type='switch'
                checked={state.entity.sole_trader ? true : false}
                options={['']}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Check
                title='Is Reseller'
                name='is_reseller'
                type='switch'
                checked={state.entity.is_reseller ? true : false}
                options={['']}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Address'
                className='form-control'
                type='string'
                name='company_addresses.0.address'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_addresses?.[0]?.address}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='City'
                className='form-control'
                type='string'
                pattern='^([A-Za-z \g])+$'
                name='company_addresses.0.city'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_addresses?.[0]?.city}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='County'
                className='form-control'
                type='string'
                name='company_addresses.0.county'
                pattern='^([A-Za-z \g])+$'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_addresses?.[0]?.county}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Country'
                name='company_addresses.0.country'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCountryValue}
                autoCompleteTokenUrl='/company/countriesautocomplete'
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Postcode'
                className='form-control'
                type='string'
                name='company_addresses.0.postcode'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_addresses?.[0]?.postcode}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Address Contact Phone'
                className='form-control'
                name='company_addresses.0.phone'
                pattern='^\d+$'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_addresses?.[0]?.phone}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Email'
                className='form-control'
                type='string'
                name='company_addresses.0.email'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_addresses?.[0]?.email}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Target Consumption Ratio (%)'
                className='form-control'
                type='string'
                name='target_consumption_ratio'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.target_consumption_ratio}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { CompanyEditModal }
